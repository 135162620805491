import React from "react"
import "../assets/components/bootstrap/css/bootstrap.min.css"
import "../assets/scss/theme.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Terms = () => {
  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <div className="container pt-10 pt-sm-12 px-2 px-sm-0 mb-12">
        <h3 className="pt-0 pt-sm-6">
          Terms and conditions of the Card Noir Visa
        </h3>
        <br />
        <p>
          These Card Noir Pre-Paid Card Terms and Conditions ("Terms and
          Conditions") set forth the terms and conditions under which you may
          use the Card Noir Card to obtain cash goods, and services through
          automated-teller-machine ("ATM") and point-of-sale ("POS")
          transactions. The Card Noir Card is issued to you by Hallmark Trust,
          via Card Noir Ltd (also referred to in these Terms and Conditions as
          the "Bank," "we," or "us"). In these terms and conditions, the words
          "you" and "your" refer to the person to whom the Card Noir Card is
          issued and any other person you've authorized to use the Card Noir
          Card. The Card Noir Card will be issued to you upon completion of your
          application and our acceptance for the Card Noir Card. You must sign
          the Card Noir Card before using it. By receiving and using the Card
          Noir Card, you agree with us that you, the Card Noir Card, and the
          money or funds stored on the Card Noir Card, are subject to these
          Terms and Conditions.
        </p>

        <p>
          The Card Noir Card is only offered to individuals over the age of 18
          who can lawfully enter into and form contracts under applicable law;
          by applying for or using the Card Noir Card, you represent and warrant
          to the Bank that you are such an individual. We may at our sole
          discretion refuse to issue the Card Noir Card to anyone for any
          reason.
        </p>

        <p>Your Card Noir Pre-paid Card and Loading Value on the Card</p>

        <p>
          The Card Noir Card is a prepaid stored value Card and is an access
          device for funds on the card account.
        </p>

        <p>
          You may only access the funds in this stored-value account with your
          Card Noir Card in accordance with these Terms and Conditions. You may
          not make any deposit, withdrawal, or any other transaction with the
          Card Noir Card or the account, except for the Card Noir Card
          transactions described in these Terms and Conditions.
        </p>

        <p>
          Once the funds are loaded onto the Card Noir Card, the funds belong to
          you, and you may use the Card Noir Card for the types of transactions
          described in these Terms and Conditions.
        </p>

        <p>
          The Bank does not pay any interest on the funds in the Card Noir Card
          account. The Card Noir Card has a $10.00 minimum balance requirement.
        </p>

        <p>
          The amount of funds shown on the Bank's records, and as stored on the
          Card Noir Card, will determine the balance on the Card Noir Card,
          unless you can support a dispute to our satisfaction.
        </p>

        <p>
          You hereby authorize us to deduct any applicable fees, as set forth
          and defined in these Terms and Conditions for your Card Noir Card.
          There is no credit line associated with your Card Noir Card. This
          means that you must have a sufficient balance of funds on the Card
          Noir Card at the time of a transaction to pay for the transaction. If
          a merchant or an ATM operator attempts to submit a transaction on the
          Card Noir Card for an amount that is greater than the current balance
          on your Card Noir Card, the transaction may not be approved by the
          Bank. In the event that the Bank, in its sole discretion, settles or
          pays a transaction with your Card Noir Card when there are
          insufficient funds stored on the Card Noir Card to pay for the
          transaction, this will result in an overdraft on your Card Noir Card.
          You agree to pay the Bank the amount of the overdraft and any related
          fee. We may deduct the overdraft amount and related fee from your Card
          Noir Card.
        </p>

        <p>
          <strong>Consent and Use of Personal Data</strong>
        </p>

        <p>
          By your acceptance and use of the Card, and your provision to us of
          your Personal Data, you hereby consent to and approve of the
          collection of your Personal Data and its storage and its use for
          identity verification. You acknowledge the need for us to monitor the
          use of services in order to offer you needed services, and you hereby
          consent to and approve of the use of your Personal Data for this
          purpose.
        </p>

        <p>
          <strong>Funds Availability</strong>
        </p>

        <p>
          Funds deposited in the Bank for credit to your Card Noir Card are
          available when the bank receives the deposit.
        </p>

        <p>
          <strong>
            Your Personal Identification Number/Signature on the Card Noir Card
          </strong>
        </p>

        <p>
          To protect the use of your Card Noir Card for ATM transactions, a
          personal identification number ("PIN") will be required to conduct a
          transaction with your Card Noir Card at ATMs. This PIN will be
          provided to you at or around the time your Card Noir Card is issued.
          You should not disclose your PIN to anyone. If the security or
          confidentiality of your PIN is compromised, you should notify the us
          at once. You are liable for any transactions made by a person you
          authorize or permit to use your Card Noir Card and/or PIN. If you
          permit someone else to use your Card Noir Card, we will treat this as
          if you have authorized this person to use your Card Noir Card and you
          will be responsible for any transactions initiated by such person with
          your Card Noir Card or Card Noir Card number.
        </p>

        <p>
          There is a panel on the back of the Card Noir Card for your signature.
          You should sign this panel as soon as you receive your Card Noir Card
          because it helps protect your Card Noir Card from unauthorized use.
          However, your responsibility for Card Noir Card transactions, as
          described in these Terms and Conditions, does not depend on whether
          you sign your Card Noir Card.
        </p>

        <p>
          <strong>Card Noir Ltd</strong>
        </p>

        <p>
          Card Noir Ltd may retain the right to deduct funds stored on the Card
          Noir Card to correct a previous error or overpayment to you or for
          other legal reasons. You hereby authorize the Bank to accept
          instructions from Card Noir Ltd to add or deduct funds from your Card
          Noir Card account, or in the case of a deduction to return those
          funds.. If you have a dispute with Card Noir Ltd about the amount of
          your proceeds, or the amount that is loaded onto or deducted from the
          Card Noir Card account, you agree to not involve the Bank in the
          dispute and to resolve the dispute solely with Card Noir Ltd and it's
          customer service affiliates.
        </p>

        <p>
          <strong>Transactions with Your Card Noir Card</strong>
        </p>

        <p>
          Your Card Noir Card may be used to access the funds stored in your
          Card Noir Card Account. Provided that funds are available in your Card
          Noir Card account, you may use your Card to perform the following
          types of transactions in accordance with these Terms and Conditions:
          At any ATM displaying the VISA logo, you may use your Card Noir
          Card (subject to the transaction limits specified in these Terms and
          Conditions) to (I) make withdrawals of cash up to the amount of funds
          in your Card Noir Card account, or to obtain Card Noir Card account
          balance information. The Card Noir Card can only be used at ATMs
          displaying the appropriate network identification.
        </p>

        <p>
          At any merchant location displaying the VISA logos,
          you may use your Card Noir Card (subject to the transaction limits
          specified in these Terms and Conditions) to purchase goods and
          services (and obtain cash) up to the amount of funds in your Card Noir
          Card account.
        </p>

        <p>
          <strong>Daily Transaction Limits</strong>
        </p>

        <p>
          The following daily withdrawal and purchasing limits will apply to
          your use of your Card Noir Card: $5,000.00 withdrawal limit per day,
          five transactions per day at any ATM that accepts Visa Cards.
        </p>

        <p>
          $5,000.00 purchase limit per day at any merchant that accepts
          Visa Cards.
        </p>

        <p>
          <strong>Foreign-Currency Transactions</strong>
        </p>

        <p>
          If you make a transaction in currency other than US dollars Visa
          (the network) will convert the debit or credit into a US dollar
          amount. The conversion rate will be determined using the network
          currency-conversion procedures then in effect. The conversion rate on
          the processing date may differ from the rate on the date of your
          transaction. Currently, the network uses a currency conversion rate of
          either a wholesale market rate or a government-mandated rate,
          increased by all adjustment factor determined by it. In each case, the
          network uses the rate in effect one day before the conversion date. We
          will post the converted U.S.-dollar amount determined by the network,
          as described above, to your Card Noir Card account.
        </p>

        <p>
          <strong>Documentation of Card Noir Card Transactions</strong>
        </p>

        <p>
          You will receive the following documentation with your Card Noir Card
          transactions:
        </p>

        <p>
          At the time you make a Card Noir Card ATM transaction or merchant POS
          transaction, you will obtain a receipt for the transaction.
        </p>

        <p>
          You can determine the balance of funds available in your Card Noir
          Card account (I) at any ATM displaying the Visa logos, (ii) by
          calling us, or (iii) via the Internet at www.cartenoir.com You also
          can call us to find out whether other proceeds have been deposited
          into your Card Noir Card account.
        </p>

        <p>
          <strong>Disputes with Merchants</strong>
        </p>

        <p>
          If you use your Card Noir Card at a merchant and a dispute with the
          merchant arises, you agree to make a good-faith effort to settle the
          dispute with the merchant. Any unresolved dispute may be sent to us in
          writing for assistance in settlement. Except as expressly provided for
          preauthorized payments in these Terms and Conditions, you cannot stop
          payment to merchants for transactions made with your Card Noir Card.
        </p>

        <p>
          <strong>Your Card-Use Liability</strong>
        </p>

        <p>
          Contact us IMMEDIATELY if you believe your Card Noir Card has been
          lost or stolen. You could lose all the money stored on your Card Noir
          Card. If your Card Noir Card is used in connection with an
          unauthorized transaction that was processed through the Visa
          Network.
        </p>

        <p>
          <strong>Bank Liability</strong>
        </p>

        <p>We will not be liable:</p>

        <p>
          If, through no fault of ours, you do not have enough money in your
          Card Noir Card account to make the transaction.
        </p>

        <p>
          If the ATM where you are making the Card Noir Card transaction does
          not have enough cash.
        </p>

        <p>
          If the terminal or system was not working properly and you knew about
          the breakdown when you started the Card Noir Card transaction.
        </p>

        <p>If we are prohibited by law from completing the transaction.</p>

        <p>
          If circumstances beyond our control (such as fire or flood or other
          Force Majeure) prevent the Card Noir Card transaction, despite
          reasonable precautions that we have taken.
        </p>

        <p>
          <strong>Disclaimer of Liability</strong>
        </p>

        <p>
          In providing the Card Noir Card and related services to you, the Bank
          and Card Noir Ltd disclaims any duty or responsibility other than
          those expressly set forth in these Terms and Conditions.
        </p>

        <p>
          <strong>Bank Right of Set-Off</strong>
        </p>

        <p>
          You agree that the Bank and us are authorized at any time to set-off
          the funds deposited into your Card Noir Card account against your
          debts or liabilities owed to the Bank. The Bank and us may exercise
          this right of set-off without notice to you.
        </p>

        <p>
          <strong>Termination</strong>
        </p>

        <p>
          The Card Noir Card shall remain the property of the Bank, and you
          agree upon demand to return your Card Noir Card to the Bank. At any
          time and without prior notice we may revoke or cancel your Card Noir
          Card and thereby terminate these Terms and Conditions and demand
          return of your Card Noir Card. You may terminate these Terms and
          Conditions at any time by returning your Card Noir Card to us.
          Termination whether by you or by us, shall not affect prior
          transactions or obligations relating to your Card Noir Card existing
          at the time of termination. Upon termination, we will arrange to
          transfer any remaining funds stored on the Card Noir Card to you.
        </p>

        <p>
          <strong>Amendment</strong>
        </p>

        <p>
          We may amend these Terms and Conditions by delivering notice of the
          amendment to you in the manner agreed to, or to your last electronic
          address as shown on our record. You will be given at least 21 days'
          notice prior to the effective date of any amendment that results in an
          increased fee or charge, an increase in your liability, a reduction in
          services offered by us, or stricter limitations on transaction or
          withdrawal rights.
        </p>

        <p>
          <strong>Assignment</strong>
        </p>

        <p>
          You may nor transfer or assign these Terms and Conditions to any other
          person without our prior written consent. We may assign our
          obligations to you under these Terms and Conditions without your
          consent or any prior notice.
        </p>

        <p>
          <strong>Severability/No Waiver</strong>
        </p>

        <p>
          If any provision of these Terms and Conditions shall be deemed
          unlawful, void, or for any reason unenforceable, then that provision
          shall be deemed severable from these Terms and Conditions and shall
          not affect the validity and enforceability of any remaining
          provisions. Our failure to enforce the strict performance of any
          provision of these Terms and Conditions will not constitute a waiver
          of our rights to subsequently enforce such provision or any other
          provisions of these Terms and Conditions.
        </p>

        <p>
          <strong>Governing Law</strong>
        </p>

        <p>
          These Terms and Conditions, the Card Noir Card, and all transactions
          hereunder are subject to the laws of the Turks and Caicos Islands.
        </p>

        <p>
          <strong>Communication and Notice of Errors</strong>
        </p>

        <p>
          Any electronic communication will take place according to the
          provisions of this section. The term "electronic communication" means
          any communication of instructions by telephone, wire or other method
          of telecommunication or electronic transmission, including a facsimile
          transmission or personal computer.
        </p>

        <p>
          We will consider any electronic communication apparently received from
          you or in your name to be duly authorized by you. You authorize Card
          Noir Ltd and the Bank to rely and act on any such communication. If
          the communication is by facsimile transmission, we will be entitled to
          act upon any signature purporting to be your signature. If Card Noir
          Ltd or the Bank tries to verify your signature on a facsimile
          transmission or the validity of any instructions electronically
          communicated and is unable to do so to its satisfaction, we may delay
          in acting on or refuse to act on such instructions.
        </p>

        <p>
          In case of errors or questions about your Card Noir Card transactions,
          telephone us at +1 (305) 560-5395, or email us at
          support@cartenoir.com. If you think your statement or receipt is
          wrong, or if you need more information about a Card Noir Card
          transaction listed on the statement or receipt, notify us as soon as
          possible. We must hear from you no later than 45 days after problem or
          error appeared.
        </p>

        <p>
          <strong>Electronic consent</strong>
        </p>

        <p>
          By using the Card Noir Card or depositing funds to the Card Noir Card,
          you are agreeing to these Terms and Conditions. Your use of the Card
          Noir Card is also your acknowledgment that you have received a copy of
          these Terms and Conditions in either paper or electronic form and that
          you have read, understand and acknowledge this agreement.
        </p>

        <p>
          <strong>Disclaimer</strong>
        </p>

        <p>
          You agree to indemnify and hold the Bank, the Processor and Card Noir
          Ltd and its subsidiaries affiliates, officers, agents and other
          partners and employees harmless from any claim or demand including
          reasonable legal fees, made by you or any third party due to or
          arising from misuse of your Card Noir Card, violation of any of these
          terms and conditions or other illegal or fraudulent card activity.
        </p>
      </div>
    </Layout>
  )
}

export default Terms
